<template>
  <div class="portfolio-page">
    <div class="round-right"></div>
    <div class="round-left"></div>
    <div class="container">
      <div class="page-title w-100">
        Наше <span>портфолио</span>
      </div>
      <div class="portfolio-tabs box-shadow">
        <div class="portfolio-tab"
             @click="currentTab = 'all'"
             :class="{ 'portfolio-tab-active': currentTab === 'all' }"
        >
          Все
        </div>
        <div class="portfolio-tab"
             @click="currentTab = 'sites'"
             :class="{ 'portfolio-tab-active': currentTab === 'sites' }"
        >
          Сайты
        </div>
        <div class="portfolio-tab"
             @click="currentTab = 'mobiles'"
             :class="{ 'portfolio-tab-active': currentTab === 'mobiles' }"
        >
          Мобильные приложения
        </div>
        <div class="portfolio-tab"
             @click="currentTab = 'portals'"
             :class="{ 'portfolio-tab-active': currentTab === 'portals' }"
        >
          Порталы
        </div>
      </div>

      <transition :name="transitionName" appear>
        <div v-if="currentTab === 'all'">
          <div class="portfolio-block">

            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/app/birge-app-big.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/app/gastreat-app-big.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/app/qabilet-app-big.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/app/wb-app-big.png')` }"></div>

            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/aerc-site-min.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/dashboard-site-big.png')` }"></div>
            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/elbasy-site-min.png')` }"></div>

            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/ktzh-site-min.png')` }"></div>
            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/mynbala-site-min.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/proctoring-site-big.png')` }"></div>

            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/qabilet-site-min.png')` }"></div>
            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/reghub-site-min.png')` }"></div>
            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/rent-site-min.png')` }"></div>
            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/texme-site-min.png')` }"></div>

            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/portal/eri-portal-big.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/portal/qamqor-portal-big.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/portal/reestr-portal-big.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/portal/smartustaz-portal-big.png')` }"></div>

          </div>

          <div class="portfolio-block-mobile">

            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/app/birge-app-big.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/app/gastreat-app-big.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/app/qabilet-app-big.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/app/wb-app-big.png')` }"></div>

            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/aerc-site-min.png')` }"></div>
            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/elbasy-site-min.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/dashboard-site-big.png')` }"></div>

            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/ktzh-site-min.png')` }"></div>
            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/mynbala-site-min.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/proctoring-site-big.png')` }"></div>

            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/qabilet-site-min.png')` }"></div>
            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/reghub-site-min.png')` }"></div>
            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/rent-site-min.png')` }"></div>
            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/texme-site-min.png')` }"></div>

            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/portal/eri-portal-big.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/portal/qamqor-portal-big.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/portal/reestr-portal-big.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/portal/smartustaz-portal-big.png')` }"></div>

          </div>
        </div>
      </transition>

      <transition :name="transitionName">
        <div v-if="currentTab === 'sites'">
          <div class="portfolio-block">
            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/aerc-site-min.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/dashboard-site-big.png')` }"></div>
            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/elbasy-site-min.png')` }"></div>

            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/ktzh-site-min.png')` }"></div>
            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/mynbala-site-min.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/proctoring-site-big.png')` }"></div>

            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/qabilet-site-min.png')` }"></div>
            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/reghub-site-min.png')` }"></div>
            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/rent-site-min.png')` }"></div>
            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/texme-site-min.png')` }"></div>


          </div>

          <div class="portfolio-block-mobile">
            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/aerc-site-min.png')` }"></div>
            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/elbasy-site-min.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/dashboard-site-big.png')` }"></div>

            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/ktzh-site-min.png')` }"></div>
            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/mynbala-site-min.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/proctoring-site-big.png')` }"></div>

            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/qabilet-site-min.png')` }"></div>
            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/reghub-site-min.png')` }"></div>
            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/rent-site-min.png')` }"></div>
            <div class="portfolio-item-min box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/site/texme-site-min.png')` }"></div>


          </div>
        </div>
      </transition>

      <transition :name="transitionName">
        <div v-if="currentTab === 'mobiles'">
          <div class="portfolio-block">
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/app/birge-app-big.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/app/gastreat-app-big.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/app/qabilet-app-big.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/app/wb-app-big.png')` }"></div>
          </div>
          <div class="portfolio-block-mobile">
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/app/birge-app-big.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/app/gastreat-app-big.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/app/qabilet-app-big.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/app/wb-app-big.png')` }"></div>
          </div>
        </div>
      </transition>

      <transition :name="transitionName">
        <div v-if="currentTab === 'portals'">
          <div class="portfolio-block">
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/portal/eri-portal-big.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/portal/qamqor-portal-big.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/portal/reestr-portal-big.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/portal/smartustaz-portal-big.png')` }"></div>
          </div>
          <div class="portfolio-block-mobile">
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/portal/eri-portal-big.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/portal/qamqor-portal-big.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/portal/reestr-portal-big.png')` }"></div>
            <div class="portfolio-item-big box-shadow"
                 :style="{ 'background-image': `url('./css/images/portfolio/portal/smartustaz-portal-big.png')` }"></div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>


<script>

import Vue from "vue";

export default {
  name: 'Portfolio',
  data() {
    return {
      transitionName: 'fade',
      currentTab: "all",
    }
  },
}
</script>
<style>

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.7s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

</style>
